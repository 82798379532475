<template>
    <div class="pt-16 px-2 ">
        <h1 class="text-red text-lg mb-6">
            Edit Product
        </h1>
        <div class="" v-if="product">
            <FormTemplate @response="formResponse" button="Save" method="post" action="/product" :formdata="formData">
                <TextInput type="text" label="Name" v-model="product.name" :required="false" ></TextInput>
                <TextInput type="text" label="Price" v-model="product.price" :required="false" ></TextInput>
                <TextInput type="text" label="Description" v-model="product.description" :required="false" ></TextInput>
                <SelectInput label="Download" v-model="product.download" :options="[{label:'Yes',value:1},{label:'No',value:0}]" :required="false" ></SelectInput>
                <SelectInput label="Subscription" v-model="product.subscription" :options="[{label:'Yes',value:1},{label:'No',value:0}]" :required="false" ></SelectInput>
                <TextInput type="text" label="Subscription Unit" v-model="product.sub_unit" :disabled="true" ></TextInput>
                <TextInput type="number" label="Subscription Unit Length" v-model="product.sub_unit_length" min="1"  max="12"></TextInput>
                <TextInput type="number" label="Subscription Occurances" v-model="product.sub_occurances" ></TextInput>
                <CustomFields :fields="product.custom_fields" />
                <div class="flex flex-col flex-wrap my-4">
                    <div class="w-full flex items-center">
                        <h2 class="text-lg">Variations</h2>
                        <ButtonTemplate :on_click="AddNewVariation" type="button" btn_style="secondary" class="h-10" >
                            New Variation
                        </ButtonTemplate>
                    </div>
                    <div class="flex flex-wrap w-full ">
                        <div v-for="(variation,var_index) in product.variations" :key="variation.id" class="flex my-4 border-2 flex-wrap w-1/2 p-2">
                            <TextInput :disabled="true" type="text" label="Variation ID" v-model="variation.id" :required="false" ></TextInput>
                            <TextInput :disabled="true" type="text" label="Product ID" v-model="product.id" :required="false" ></TextInput>
                            <SelectInput label="Type" v-model="variation.type" :options="[{label:'subscription',value:'subscription'},{label:'quantity',value:'quantity'},{label:'product',value:'product'}]" :required="false" ></SelectInput>
                            <SelectInput label="Operator" v-model="variation.operator" :options="[{label:'AND',value:'&&'},{label:'NULL',value:null}]" :required="false" ></SelectInput>
                            <SearchSelectInput v-if="variation.operator == '&&'" class="w-full" @item:removed="variationIDRemoved($event,variation)" @item:selected="variationIDSelected($event,variation)" :params="variationIDParams" label="Variation References" v-model="variation.variation_id" :optiondisplay="variationIDDisplay" :hideSelected="true"></SearchSelectInput>
                            <div v-if="variation.variation_ref && variation.operator == '&&'"  class="flex flex-wrap border m-2">
                                <div v-for="(attribute,i) in variation.variation_ref" :key="i" class="flex flex-col items-center justify-start mx-2 p-2">
                                    <div class="text-bold">
                                        {{i}}
                                    </div>
                                    <div>
                                        "{{attribute}}"
                                    </div>
                                </div>
                            </div>
                            <SelectInput label="Comparison" v-model="variation.comparison" :options="[{label:'Equals',value:'='},{label:'Does Not Equal',value:'!='},{label:'Less Than',value:'<'},{label:'Greater Than',value:'>'}]" :required="false" ></SelectInput>
                            <TextInput type="text" label="Comparison Min" v-model="variation.comparison_min" :required="false" ></TextInput>
                            <TextInput type="text" label="Comparison Max" v-model="variation.comparison_max" :required="false" ></TextInput>
                            <SelectInput label="Attribute" v-model="variation.attribute" :options="productAttributeOptions" :required="false" ></SelectInput>
                            <TextInput type="text" label="Attribute Value" v-model="variation.attribute_value" :required="false" ></TextInput>
                            <button @click="RemoveVariation(var_index)" type="button" class="h-10 my-6 text-white bg-red font-open text-normal font-semibold px-6 py-1"> Remove Variation </button>
                        </div>
                    </div>
                </div>
                <SearchSelectInput @item:removed="serviceRemoved" @item:selected="serviceSelected" :params="serviceSearchParams" :multiple="true" label="Services" v-model="product.services" :optiondisplay="serviceDisplay"></SearchSelectInput>
                <SearchSelectInput @item:removed="couponRemoved" @item:selected="couponSelected" :params="couponSearchParams" :multiple="true" label="Coupons" v-model="product.coupons" :optiondisplay="couponDisplay"></SearchSelectInput>
                <SearchSelectInput @item:removed="downloadRemoved" @item:selected="downloadSelected" :params="downloadSearchParams" :multiple="true" label="Downloads" v-model="product.downloads" :optiondisplay="downloadDisplay"></SearchSelectInput>
                <div class="">
                    <TextInput type="text" label="Product Tags (Type and press Enter to add tag)" v-model="product_tag" :keyup="AddTag"></TextInput>  
                    <div class="flex flex-wrap">
                        <div class="m-4 px-4 py-2 bg-yellow text-black flex items-center justify-center" v-for="(tag,i) in product.tags" :key="i">
                            <div>{{tag}}</div>
                            <button type="button" title="Remove Tag" class="ml-5 text-red font-bold" @click="removeTag(i)">X</button>
                        </div>
                    </div>                  
                </div>
            </FormTemplate>
        </div>
    </div>
</template>

<script>
import Axios from 'axios'
export default {
    name: 'Product',
    data: () => ({
        product: false,
        product_tag: '',
        serviceSearchParams: {
            'model': 'Service',
            'fields': ['name'],
            'action': '/search/',
        },
        couponSearchParams: {
            'model': 'Coupon',
            'fields': ['code'],
            'action': '/search/',
        },
        downloadSearchParams: {
            'model': 'ProductDownload',
            'fields': ['name'],
            'action': '/search/',
        },
        variationIDParams: {
            'model': 'ProductVariation',
            'fields': ['id','type'],
            'action': '/search/',
        },
        remove_downloads: [],
        remove_variations: [],
        productAttributeOptions: [
            {
                label: 'Name',
                value:'name',
            },
            {
                label: 'Price',
                value:'price',
            },
            {
                label: 'Description',
                value:'description',
            },
            {
                label: 'Download',
                value:'download',
            },
            {
                label: 'Subscription',
                value:'subscription',
            },
            {
                label: 'License',
                value:'license',
            },
            {
                label: 'Subscription Unit',
                value:'sub_unit',
            },
            {
                label: 'Subscription Unit Length',
                value:'sub_unit_length',
            },
            {
                label: 'Subscription Occurances',
                value:'sub_occurances',
            },
        ],
    }),
    computed: {
        formData() {
            return {
                'product':this.product,
                'remove_downloads': this.remove_downloads,
                'remove_variations': this.remove_variations,
            }
        },
    },
    props: [],
    methods: {
        removeTag(index) {
            this.product.tags.splice(index,1)
        },
        AddTag() {
            if(!this.product_tag || this.product_tag.trim == '') return
            this.product.tags.push(this.product_tag)
            this.product_tag = ''
        },
        AddNewVariation() {
            this.product.variations.push({
                id: 0,
                product_id: this.product.id,
                type: null,
                operator: null,
                variation_id: null,
                comparison: null,
                comparison_max: null,
                attribute: null,
                attribute_value: null,
            })
        },
        RemoveVariation(index) {
            if(this.product.variations[index].id) this.remove_variations.push(this.product.variations[index])
            this.product.variations.splice(index,1)
        },
        variationIDDisplay(item) {
            let display = ''
            for(let i in item) {
                if(!['created_at','updated_at'].includes(i)) display += ` [ ${i} : '${item[i]}' ] `
            }
            return display
        },
        variationIDSelected(event,variation) {
            variation.variation_id = event.item.id
            this.$set(variation,'variation_ref', event.item)
        },
        variationIDRemoved(event,variation){
            variation.variation_id = null
            this.$set(variation,'variation_ref', null)
        },
        downloadDisplay(item) {
            return item.name;
        },
        downloadSelected(evt) {
            let index = this.product.downloads.findIndex((download)=>{return download.id == evt.item.id})
            if(index < 0) this.product.downloads.push(evt.item);
        },
        downloadRemoved(evt) {
            this.remove_downloads.push(evt.item);
            this.product.downloads.splice(evt.index,1);
        },
        couponDisplay(item) {
            return item.code;
            //return `<a title='Open Coupon: "${item.code}" in New Tab' target='_blank' href='/coupons/${item.id}'>${item.code}</a>`;
        },
        couponSelected(evt) {
            let index = this.product.coupons.findIndex((coupon)=>{return coupon.id == evt.item.id})
            if(index < 0) this.product.coupons.push(evt.item);
        },
        couponRemoved(evt) {
            this.product.coupons.splice(evt.index,1);
        },
        serviceDisplay(item) {
            return item.name;
        },
        serviceSelected(evt) {
            let index = this.product.services.findIndex((service)=>{return service.id == evt.item.id})
            if(index < 0) this.product.services.push(evt.item);
        },
        serviceRemoved(evt) {
            this.product.services.splice(evt.index,1);
        },
        formResponse(re) {
            if(re.data.status == 'OK') {
                this.$notify({
                    title: 'Product updated',
                    text: '',
                    type: 'success',
                });
            }
        },
        getProduct(product_id) {
            let that = this;
            Axios.get(`/product/${product_id}`).then(re=>{
                that.product = re.data.product;
                that.product.tags = that.product.tags
            }).catch(err=>{
                if(err.response && err.response.status == 403) {
                    that.$notify({
                        title: 'You are not allowed to do this...',
                        text: 'Get lost',
                        type: 'error',
                    });
                }else{
                    this.$notify({
                        title: 'Whoops something went wrong.',
                        text: err.message,
                        type: 'error',
                    });
                }
            });
        },
    },
    watch: {
    },
    created() {
        this.getProduct(this.$route.params.product_id);
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
    },
}
</script>
<style scoped>
</style>
