<template>
<div class="container pt-16 px-2 ">
    <div class="flex">
        <h1 class="text-red text-lg mb-6">
            Products
        </h1>
        <LinkTemplate :to="{path:'/products/create'}" class="my-6 ml-6">
            Add New Product
        </LinkTemplate>
    </div>
    <div class="">
        <TextInput @input="maybeSearch" type="text" label="Search" v-model="search" :required="false" ></TextInput>
    </div>
    <TableTemplate v-if="loading == false" @item:deleted="ItemDeleted" :candelete="false" canview="/products/" :columns="columns" :items="products"></TableTemplate>
    <img v-else class="m-auto my-4" style="width:50px;height:50px;" src="/assets/loading.gif" alt="Loading search submission">
</div>
</template>

<script>
import Axios from 'axios'

export default {
    name: 'ProductList',
    data: () => ({
        search: '',
        search_timeout: false,
        columns:[
            {
                id:'id',
                label:'ID',
            },
            {
                id:'name',
                label:'Name',
            },
            {
                id: 'tags',
                label: 'Tags',
            },
            {
                id:'price',
                label:'Price',
            },
        ],
        products: [],
        loading: false,
    }),
    computed: {
    },
    props: [],
    methods: {
        doSearch() {
            let that = this;
            this.loading = true;
            Axios.post('/search',{
                search: that.search,
                params: {
                    'model': 'Product',
                    'fields': ['name','price'],
                },
            }).then(re=>{
                that.products = re.data.items
                that.loading = false
            }).catch(err=>{
                console.log(err);
                if(err.response.status == 403) {
                    that.$notify({
                        title: 'You are not allowed to do this...',
                        text: 'Get lost',
                        type: 'error',
                    });
                }else{
                    this.$notify({
                        title: 'Whoops something went wrong.',
                        text: err.message,
                        type: 'error',
                    });
                }
            });
        },
        maybeSearch() {
            if(this.search_timeout) clearTimeout(this.search_timeout);
            let that = this;
            if(!that.search || !that.search.length){
                that.getProducts();
                return;
            }
            this.search_timeout = setTimeout(function(){ that.doSearch() }, 1000);
        },
        ItemDeleted(evt) {
            let that = this
            Axios.get(`/product/remove/${evt.item.id}`).then((re)=>{
                if(re.data.status == 'OK') {
                    that.coupons.splice(evt.index,1)
                    this.$notify({
                        title: 'Product Deleted',
                        text: '',
                        type: 'success',
                    });
                }else{
                    this.$notify({
                        title: 'Product Not Deleted',
                        text: re.data.message,
                        type: 'warning',
                    });
                }

            }).catch(err=>{
                if(err.response.status == 403) {
                    that.$notify({
                        title: 'You are not allowed to do this...',
                        text: 'Get lost',
                        type: 'error',
                    });
                }else{
                    this.$notify({
                        title: 'Whoops something went wrong.',
                        text: err.message,
                        type: 'error',
                    });
                }
            })
        },
        getProducts() {
            let that = this
            Axios.get('/products').then(re=>{
                that.products = re.data.products
            }).catch(err=>{
                if(err.response.status == 403) {
                    that.$notify({
                        title: 'You are not allowed to do this...',
                        text: 'Get lost',
                        type: 'error',
                    });
                }else{
                    this.$notify({
                        title: 'Whoops something went wrong.',
                        text: err.message,
                        type: 'error',
                    });
                }
            })
        },
    },
    watch: {
    },
    created() {
        this.getProducts()
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
    },
}
</script>
<style scoped>
</style>
